import React,{useState,useEffect }from 'react';

import'./style.css';
import'./responsive.css';
import logom from '../../Assets/img/logo.svg'
import logoo from '../../Assets/img/logo1.svg'

import $ from 'jquery'

export default function Menu (){

  const [logo,setLogo] = useState(logom);

  useEffect(()=>{
    $(document).ready(function($){
      $(window).scroll(function() {    
       if ($(window).scrollTop() >= 20) {
         $('.menu_wrapper').addClass('back');
         $('.tel').addClass('scrollTel');
         
         setLogo(logoo)
       }else{
           $('.menu_wrapper').removeClass('back');
           $('.tel').removeClass('scrollTel');
           setLogo(logom)
       }
   });
 });
  },[])

    return <div className="menu_wrapper">
        <div className="logo">
            <img src={logo} alt="logomarca"/>
        </div>
        <div className="nav"></div>
        <div className="contato">

            {/* <div className="circle_icon"><i class="fas fa-phone"></i></div> */}
            <div className="tel">
                {/* <div className="text"></div> */}
                entre em contato
                </div>

        </div>
    </div>;
  
}
