import React from 'react';

import './style.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

// import img1 from '../../Assets/img/1.png'
// import img2 from '../../Assets/img/2.png'
// import img3 from '../../Assets/img/3.png'
// import img4 from '../../Assets/img/4.png'
import img5 from '../../Assets/img/5.png'


import ScrollAnimation from 'react-animate-on-scroll';

export default function Slide() {
  return (
      <>
    <ScrollAnimation animateIn="fadeIn" animateOnce>
      <div className="slide_container">

      <div className="headline_group">
            <div className="headline_text">INCLUSO: <span> 6 MESES GRÁTIS</span> DE HOSPEDAGEM DE SITES</div>
            {/* <p>MELHOR ANTISPAM DO BRASIL E SUPORTE TÉCNICO HUMANIZADO</p> */}
            </div>
            <div className="slide_wrapper">
            <Carousel 
            showThumbs={false}

            >
                <div>
                    <img src={img5} alt="Slide" />
                </div>

   
            </Carousel>
            </div>
      </div>
      </ScrollAnimation>

</>
  );
}
