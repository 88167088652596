import React, { Component } from 'react';

import './style.css';
import './responsive.css';


export default class Home extends Component {
  render() {
    return (
        <div className="home_wrapper_misto">
            <div className="headline_wrapper">
              <div className="headline_group">
              <div className="headline_text">POSSUÍMOS INTEGRAÇÃO COM O SERVIÇO DA GOOGLE<br/><span> REDUÇÃO DE ATÉ 76% DE CUSTO PARA A SUA EMPRESA</span></div>

              
                <p>Continue mantendo a qualidade de alto nível com o ambiente misto: Google x MAV Mail</p>
              </div>
       
            </div>
            {/* <div className="video_wrapper">
            <iframe title="google" width="760" height="515" src="https://www.youtube.com/embed/_jt-EE7XTt0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div> */}

        </div>
    );
  }
}
