import React from 'react';

import './App.css';
import Menu from './Components/Menu'
import HomeMisto from './Pages/HomeMisto'
import Home from './Pages/Home'
import Mail from './Pages/Mail'
import Conteudo from './Pages/Conteudo'
// import Migration from './Pages/Migration';
import Footer from './Components/Footer'
import Slide from './Components/Slide';
function App() {
  return (
    <div className="App">

      <Menu/>
      <Home/>
      <Mail/>
      <HomeMisto/>
      <Conteudo/>
      <Slide/>
      {/* <Migration/> */}
      <Footer/>

    </div>
  );
}

export default App;
