import React, { Component } from 'react';

import './style.css';
import './responsive.css';

import "react-responsive-carousel/lib/styles/carousel.min.css";

export default class Home extends Component {
  render() {
    return (
        <div className="home_wrapper">
            <div className="headline_wrapper">
              <div className="headline_group">
              <div className="headline_text">COMECE A UTILIZAR OS MELHORES RECURSOS PARA SUA HOSPEDAGEM DE E-MAILS<br/>
          <span> SÃO ATÉ 6 MESES GRÁTIS PARA VOCÊ COMEÇAR AGORA</span></div>
                <p>DIMINUA DE FORMA CONSIDERÁVEL O CUSTO DA SUA HOSPEDAGEM GOOGLE</p>
              </div>
            </div>

        </div>
    );
  }
}
